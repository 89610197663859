<template>
  <div
    class="ml-auto mr-auto p-2  md:p-6 bg-white rounded-lg mb-4 md:max-w-3/4"
  >
    <h1 class="font-bold text-lg text-center text-darkblue mb-3">
      {{
        $route.params.mode == "new" ? "New Vehicle Trip" : "Update Vehicle Trip"
      }}
    </h1>
    <div class="text-center mt-1 mb-2">
      <font-awesome-icon
        v-if="loading.vehicle"
        title="loading"
        :class="'text-dark animate-spin text-base mr-1 '"
        :icon="['fas', 'spinner']"
      />
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(SubmitVehicleTrip)" class="">
        <div class=" justify-around flex flex-col md:flex-row">
          <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
            <label class="block md:text-xs" for="FullName">
              Vehicle <span class="text-red-700">*</span>
            </label>
            <validation-provider rules="required" v-slot="{ errors }">
              <t-rich-select
                name="Vehicle"
                :fixedClasses="rich_select_component_classes"
                :classes="rich_select_default_component_classes"
                v-model="selectedVehicle"
                placeholder="Select Vehicle Plate Number"
                :options="vehicles"
              ></t-rich-select>

              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
            <label class="block md:text-xs" for="Location"
              >Location <span class="text-red-700">*</span>
            </label>
            <validation-provider
              rules="required|custom_text"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="vehicleTrip.location"
                name="location"
                class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class="  justify-around flex flex-col md:flex-row">
          <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
            <label class="block md:text-xs" for="Position"
              >Description <span class="text-red-700">*</span></label
            >
            <validation-provider
              rules="required|custom_text"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="vehicleTrip.description"
                placeholder="Description/Purpose of trip"
                name="Description"
                class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
            <label class="block md:text-xs" for="Position"
              >Income <span class="text-red-700">*</span></label
            >
            <validation-provider rules="required|numeric" v-slot="{ errors }">
              <input
                type="text"
                v-model="vehicleTrip.income"
                placeholder="Income"
                name="Income"
                class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class="  justify-around flex flex-col md:flex-row">
          <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
            <label class="block md:text-xs" for="Position"
              >Expense <span class="text-red-700">*</span></label
            >
            <validation-provider rules="required|numeric" v-slot="{ errors }">
              <input
                type="text"
                v-model="vehicleTrip.expense"
                placeholder="Expense amount"
                name="Expense"
                class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>

        <button
          type="submit"
          class="text-white block md:text-xs bg-darkblue hover:text-black hover:bg-green-400 pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full"
        >
          <svg
            v-if="loading.spinner"
            class="inline animate-spin mr-2"
            width="25"
            height="25"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48 25C48 12.2975 37.7025 2 25 2"
              stroke="black"
              stroke-width="4"
            />
            <path
              d="M48 25C48 37.7025 37.7025 48 25 48"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M2 25C2 12.2975 12.2975 2 25 2"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M25 48C12.2975 48 2 37.7025 2 25"
              stroke="#C9000C"
              stroke-width="4"
            />
          </svg>
          {{
            $route.params.mode == "new"
              ? "Create Vehicle Trip"
              : "Update Vehicle Trip"
          }}
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

  import gql from "graphql-tag";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { mapMutations } from "vuex";

  export default {
    name: "ManageVehicle",
    props: {},
    components: { FontAwesomeIcon, ValidationProvider, ValidationObserver },
    apollo: {
      getVehicles: {
        query: gql`
          query {
            getVehicles {
              _id
              plateNumber
            }
          }
        `,
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
      },

      vehicleTrip: {
        query: gql`
          query vehicleTrip($id: ID!) {
            vehicleTrip(id: $id) {
              _id
              authorId {
                _id
                name
              }
              vehicleId {
                _id
                plateNumber
              }
              balance
              expense
              income
              description
              location
              createdAt
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        skip() {
          return this.$route.params.mode != "edit";
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.vehicleTrip = true;
            return;
          }

          this.loading.vehicleTrip = false;
        },
      },
    },
    data() {
      return {
        loading: {
          vehicleTrip: false,
          spinner: false,
        },
        selectedVehicle: null,
        getVehicles: [],
        vehicleTrip: {
          _id: null,
          plateNumber: null,
          location: null,
          description: null,
          income: 0,
          balance: 0,
          expense: 0,

          authorId: {
            _id: null,
            name: null,
          },
          vehicleId: {
            _id: null,
            plateNumber: null,
          },
          createdAt: null,
        },
        rich_select_default_component_classes: {
          wrapper: "",
          buttonWrapper: "",
          selectButton:
            "px-3 rounded-lg  md:text-xs  py-2 text-black transition duration-100 ease-in-out bg-white border-darkblue border-2 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
          selectButtonLabel: "",
          selectButtonPlaceholder: "text-darkblue",
          selectButtonIcon: "text-gray-600",
          selectButtonClearButton:
            "hover:bg-blue-100 text-gray-600 rounded transition duration-100 ease-in-out",
          selectButtonClearIcon: "",
          dropdown:
            "-mt-1 bg-white  md:text-xs  border-b border-gray-300 border-l border-r rounded-b shadow-sm",
          dropdownFeedback: "pb-2  md:text-xs  px-3 text-gray-400 text-sm",
          loadingMoreResults: "pb-2 px-3 text-gray-400 text-sm",
          optionsList: "",
          searchWrapper: "p-2 placeholder-gray-400",
          searchBox:
            "px-3 py-2 bg-gray-50 text-sm  md:text-xs  rounded border focus:outline-none focus:shadow-outline border-gray-300",
          optgroup: "text-gray-400 uppercase text-xs py-1 px-2 font-semibold",
          option: "",
          disabledOption: "",
          highlightedOption: "bg-blue-100",
          selectedOption:
            "font-semibold bg-gray-100 bg-blue-500 font-semibold text-white",
          selectedHighlightedOption:
            "font-semibold bg-gray-100 bg-darkblue font-semibold text-white",
          optionContent: "flex justify-between items-center px-3 py-2",
          optionLabel: "",
          selectedIcon: "",
          enterClass: "",
          enterActiveClass: "opacity-0 transition ease-out duration-100",
          enterToClass: "opacity-100",
          leaveClass: "transition ease-in opacity-100",
          leaveActiveClass: "",
          leaveToClass: "opacity-0 duration-75",
        },
        rich_select_component_classes: {
          wrapper: "relative",
          buttonWrapper: "inline-block relative w-full",
          selectButton: "w-full flex text-left justify-between items-center",
          selectButtonLabel: "block truncate",
          selectButtonPlaceholder: "block truncate",
          selectButtonIcon: "fill-current flex-shrink-0 ml-1 h-4 w-4",
          selectButtonClearButton:
            "flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6",
          selectButtonClearIcon: "fill-current h-3 w-3",
          dropdown: "absolute w-full z-10",
          dropdownFeedback: "",
          loadingMoreResults: "",
          optionsList: "overflow-auto",
          searchWrapper: "inline-block w-full",
          searchBox: "inline-block w-full",
          optgroup: "",
          option: "cursor-pointer",
          disabledOption: "opacity-50 cursor-not-allowed",
          highlightedOption: "cursor-pointer",
          selectedOption: "cursor-pointer",
          selectedHighlightedOption: "cursor-pointer",
          optionContent: "",
          optionLabel: "truncate block",
          selectedIcon: "fill-current h-4 w-4",
          enterClass: "",
          enterActiveClass: "",
          enterToClass: "",
          leaveClass: "",
          leaveActiveClass: "",
          leaveToClass: "",
        },
      };
    },
    watch: {
      vehicleTrip: {
        // This will let Vue know to look inside the array
        deep: true,

        // We have to move our method to a handler field
        handler(newValue) {
          if (this.$route.params.mode == "edit") {
            this.selectedVehicle = newValue.vehicleId._id;
          }
        },
      },
    },
    computed: {
      vehicles: function() {
        return this.getVehicles.map((vehicle) => {
          return {
            value: vehicle._id,
            text: vehicle.plateNumber,
          };
        });
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      SubmitVehicleTrip() {
        if (this.$route.params.mode != "edit") {
          this.CreateVehicleTrip();
          return;
        }
        this.UpdateVehicleTrip();
      },
      async CreateVehicleTrip() {
        this.loading.spinner = true;
        try {
          const { location, income, description, expense } = this.vehicleTrip;
          const vehicleTripData = {
            location,
            income: parseInt(income),
            description,
            expense: parseInt(expense),
            vehicleId: this.selectedVehicle,
          };
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation createVehicleTrip($vehicleTrip: VehicleTripInput!) {
                createVehicleTrip(vehicleTrip: $vehicleTrip) {
                  _id
                  income
                  expense
                  location
                  description
                  balance
                  vehicleId {
                    _id
                    plateNumber
                  }
                  serialId
                }
              }
            `,
            // Parameters
            variables: {
              vehicleTrip: vehicleTripData,
            },
          });

          this.loading.spinner = false;
          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }

          if (!errors && !!data.createVehicleTrip._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Vehicle Trip created successfully,`,
            });

            return this.$router.push("/app/vehicle-trips");
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating vehicle trip,check your network or contact your developer`,
          });
        } catch (error) {
          this.loading.spinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating vehicle trip,check your network or contact your developer`,
          });
        }
      },
      async UpdateVehicleTrip() {
        this.loading.spinner = true;
        try {
          const { location, income, description, expense } = this.vehicleTrip;
          const vehicleTripData = {
            location,
            income: parseInt(income),
            description,
            expense: parseInt(expense),
            vehicleId: this.selectedVehicle,
          };
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation updateVehicleTrip(
                $id: ID!
                $vehicleTrip: VehicleTripInput!
              ) {
                updateVehicleTrip(id: $id, vehicleTrip: $vehicleTrip) {
                  _id
                  income
                  expense
                  location
                  description
                  balance
                  vehicleId {
                    _id
                    plateNumber
                  }
                  serialId
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
              vehicleTrip: vehicleTripData,
            },
          });

          this.loading.spinner = false;
          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }

          if (!errors && !!data.updateVehicleTrip._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Vehicle Trip updated successfully`,
            });

            return this.$router.push(
              `/app/vehicle-trips/${data.updateVehicleTrip._id}`
            );
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating vehicle trip,check your network or contact your developer`,
          });
        } catch (error) {
          this.loading.spinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating vehicle trip,check your network or contact your developer`,
          });
        }
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Vehicle Trips", pathName: "vehicleTrips" },
        ...(this.$route.params.mode == "edit"
          ? [
              {
                displayName: "Single Vehicle Trip",
                pathName: "singleVehicleTrip",
                params: { id: this.$route.params.id },
              },
            ]
          : []),
        {
          displayName: "Manage Vehicle Trip",
          pathName: "manageVehicleTrip",
          params: { id: this.$route.params.id, mode: this.$route.params.mode },
        },
      ]);

      this.$apollo.queries.getVehicles.setOptions({
        fetchPolicy: "network-only",
      });
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
